<template>
  <!-- <div class="box-container">
    <div class="d-flex justify-space-between flex-wrap mb-5">
      <div class="d-flex align-center">
        <v-avatar tile size="25" class="me-3">
          <v-icon color="primary" size="30">mdi-heart-outline</v-icon>
        </v-avatar>
        <h2 class="mb-0">{{ $t("Wishlist.title") }}</h2>
      </div>
    </div>

    <v-row>
      <v-col cols="12">
        <v-data-iterator
          :items="wishlist"
          :items-per-page.sync="itemsPerPage"
          :page.sync="page"
          hide-default-footer
        >
          <template v-slot:default="props">
            <v-row>
              <v-col
                v-for="item in props.items"
                :key="item.name"
                cols="12"
                sm="6"
                md="6"
                lg="4"
                xl="3"
              >
                <card-cart
                  card-hover
                  :title="item.producto"
                  :amount="item.cantidadEmpaque"
                  :store="item.entidad"
                  :salePrice="item.precioNeto"
                  :content-img="item.imagen || '/img/default_product_image.png'"
                  disable-add
                  @add="(cb) => addToCart(item, cb)"
                  @wishlist="(cb) => addToWishlist(item, cb)"
                />
              </v-col>
            </v-row>
          </template>

          <template v-slot:footer>
            <v-row class="my-5 me-1" align="center" justify="center">
              <v-spacer />

              <span class="mr-4 grey--text">
                Page {{ page }} of {{ numberOfPages }}
              </span>
              <v-btn
                fab
                :disabled="page == 1"
                small
                color="primary"
                class="mr-1"
                @click="formerPage"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                fab
                :disabled="page == numberOfPages"
                small
                color="primary"
                class="ml-1"
                @click="nextPage"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-row>
          </template>
        </v-data-iterator>
      </v-col>
    </v-row>
  </div> -->
  <n-grid
    ref="wishlistGrid"
    :label="$t('Wishlist.title')"
    src="api/v1/wishlist"
    sort-by="asc"
  >
    <template #item="{ item }">
      <card-cart
        card-hover
        :title="item.producto"
        :amount="item.cantidadEmpaque"
        :store="item.entidad"
        :salePrice="item.precioNeto"
        :content-img="item.imagen || '/img/default_product_image.png'"
        disable-add
        @add="(cb) => addToCart(item, cb)"
        @wishlist="(cb) => addProductToWishList(item, cb)"
      />
    </template>
  </n-grid>
</template>

<script>
import NGrid from '../../../components/newgen/NGrid';
import CardCart from '../../../components/cartCard/CardCart';
import ProductMixins from '../../../mixins/ProductMixins';

export default {
  mixins: [
    ProductMixins
  ],
  components: {
    NGrid,
    CardCart,
  },
  methods: {
    addProductToWishList(item, cb) {
      this.addToWishlist(item)
        .then(() => {
          this.$refs.wishlistGrid.reset();
        })
        .catch(() => this.$notify({ text: `No se pudo agregar a su lista de favoritos!` }))
        .finally(() => cb());
    }
  }
};
</script>